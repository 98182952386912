<script lang="ts" setup>
import { maskitoPhoneOptionsGenerator } from '@maskito/phone'
import metadata from 'libphonenumber-js/min/metadata'
import TextMasked from './TextMasked.vue'

interface Props {
    modelValue?: string | undefined;
    name: string;
    placeholder?: string;
    disabled?: boolean;
    readonly?: boolean;
    required?: boolean;
}

interface Events {
    (e: 'update:modelValue', value?: string): void;
    (e: 'focus', value: FocusEvent): void;
    (e: 'blur', value: FocusEvent): void;
}

const props = defineProps<Props>()
const emit = defineEmits<Events>()

const maskOptions = maskitoPhoneOptionsGenerator({
  countryIsoCode: 'RU',
  metadata,
})

const field = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})
</script>

<template>
    <TextMasked
        v-model="field"
        type="tel"
        autocomplete="tel"
        :name="props.name"
        :placeholder="props.placeholder"
        :disabled="props.disabled"
        :readonly="props.readonly"
        :required="props.required"
        :mask="maskOptions"
        @focus="emit('focus', $event)"
        @blur="emit('blur', $event)"
    />
</template>

<style scoped></style>
